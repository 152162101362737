import {platform} from "@/api/platform";
import {order} from "@/api/order";
import {delivery} from "@/api/delivery";
import {retryRequest} from "@/helpers/request";
import {ORDERS_LIMIT, PERMISSIONS} from "@/constants";

// Modules
import address from "./user/address";
import approval from "./user/approval";
import cart from "./user/cart";
import catalog from "./user/catalog";
import checkout from "./user/checkout";
import constraints from "./user/constraints";
import delegate from "./user/delegate";
import impersonation from "./user/impersonation";
import orders from "./user/orders";
import permissions from "./user/permissions";
import profile from "./user/profile";
import quiz from "./user/quiz";
import surveys from "./user/surveys";

export default {
    namespaced: true,
    state: {
        token: null,
        impersonatorToken: null,
        id: null,
        stylistTags: null,
        language: null,
        acknowledgement: null,
        requireAcknowledgement: null,
        loaded: false
    },
    getters: {
        isAuthenticated: state => !!state.token,
        id: state => state.id,
        token: state => state.token,
        impersonatorToken: state => state.impersonatorToken,
        stylistTags: state => state.stylistTags ? state.stylistTags : [],
        language: state => state.language,
        acknowledgement: state => state.acknowledgement,
        requireAcknowledgement: state => state.requireAcknowledgement,
        loaded: state => state.loaded
    },
    mutations: {
        setToken: (state, token) => state.token = token,
        setImpersonatorToken: (state, impersonatorToken) => state.impersonatorToken = impersonatorToken,
        setId: (state, id) => state.id = id,
        setStylistTags: (state, stylistTags) => state.stylistTags = stylistTags,
        setLanguage: (state, language) => state.language = language,
        setAcknowledgement: (state, acknowledgement) => state.acknowledgement = acknowledgement,
        setRequireAcknowledgement: (state, requireAcknowledgement) => state.requireAcknowledgement = requireAcknowledgement,
        setLoaded: (state, loaded) => state.loaded = loaded
    },
    actions: {
        fetch(context, data) {

            if (data) {

                context.commit("setToken", data.token);
                context.commit("setId", data.id);
                context.commit("setStylistTags", data.stylistTags);
                context.commit("setRequireAcknowledgement", data.requireAcknowledgement);

                this.dispatch("user/permissions/setPermissions", data.permissions.permissions);
                this.dispatch("user/permissions/setRestrictions", data.permissions.restrictions);
            }

            platform.disableUnauthorizedInterceptor();
            order.disableUnauthorizedInterceptor();
            delivery.disableUnauthorizedInterceptor();

            const promises = [
                retryRequest(() => platform.get(`/api/v2/consumers/${context.state.id}`, {params: {get: "preferred_language,acknowledgement"}})),
                this.dispatch("user/profile/fetch"),
            ];

            return Promise
                .all(promises)
                .then(responses => {

                    this.dispatch("user/surveys/fetch");
                    this.dispatch("user/checkout/fetch");

                    if (this.getters["user/permissions/hasPermission"](PERMISSIONS.MANAGER)) {

                        this.dispatch("user/approval/fetch");
                        this.dispatch("user/delegate/fetch");
                    }

                    const [consumerResponse] = responses;
                    const language = consumerResponse.data.results.preferred_language;
                    const acknowledgement = consumerResponse.data.results.acknowledgement;

                    context.commit("setLanguage", language);
                    context.commit("setAcknowledgement", acknowledgement);
                    context.commit("setLoaded", true);

                    const promises = [this.dispatch("i18n/fetch")];

                    if (
                        this.getters["user/profile/country"]
                        && this.getters["user/profile/equipment_submitted"]
                        && this.getters["user/permissions/hasPermission"](PERMISSIONS.EMPLOYEE)
                    ) {

                        promises.push(this.dispatch("user/orders/fetch", {skip: 0, limit: ORDERS_LIMIT}));
                        promises.push(this.dispatch("user/cart/fetch"));
                        promises.push(this.dispatch("user/constraints/fetch"));
                    }

                    return Promise
                        .all(promises)
                        .then(
                            () => {

                                if (language) {

                                    this.dispatch(
                                        "i18n/setLanguage",
                                        this.getters["i18n/languages"].find(lang => lang.value === language)
                                    );
                                }
                            }
                        );
                })
                .finally(() => {

                    platform.enableUnauthorizedInterceptor();
                    order.enableUnauthorizedInterceptor();
                    delivery.enableUnauthorizedInterceptor();
                })
        },
        setLanguage(context, language) {

            context.commit("setLanguage", language);

            if (context.getters["isAuthenticated"]) {

                return platform.put(`/api/v2/consumers/${context.state.id}`, {preferred_language: language});
            }
        },
        setAcknowledgement(context, acknowledgement) {

            context.commit("setAcknowledgement", acknowledgement);

            return platform.put(`/api/v2/consumers/${context.state.id}`, {acknowledgement});
        },
        logout(context, options = {force: true}) {

            if (true === options.force) {

                platform.post(`/api/v2/users/${context.state.id}/jwt/logout`);
            }

            context.commit("setId", null);
            context.commit("setToken", null);
            context.commit("setStylistTags", null);
            context.commit("setLanguage", null);

            this.dispatch("user/profile/clear");
            this.dispatch("user/address/clear");
            this.dispatch("user/cart/clear");
            this.dispatch("user/constraints/clear");
            this.dispatch("user/quiz/profile/clear");
            this.dispatch("user/surveys/clear");
            this.dispatch("user/checkout/clear");
            this.dispatch("user/approval/clear");
            this.dispatch("user/permissions/clear");
        }
    },
    modules: {
        address,
        approval,
        cart,
        catalog,
        checkout,
        constraints,
        delegate,
        impersonation,
        orders,
        permissions,
        profile,
        quiz,
        surveys
    }
}